import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NavigationDesktopComponent } from './navigation-desktop.component';

@NgModule({
  declarations: [NavigationDesktopComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [NavigationDesktopComponent],
  bootstrap: [NavigationDesktopComponent],
})
export class NavigationDesktopModule {}
