import { NgModule } from '@angular/core';
import { BarComponent } from './bar.component';
import { RouterModule } from '@angular/router';
import { NavigationDesktopModule } from '../navigation-desktop/navigation-desktop.module';
import { NavigationMobileModule } from '../navigation-mobile/navigation-mobile.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [BarComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    NavigationDesktopModule,
    NavigationMobileModule,
  ],
  exports: [BarComponent],
  bootstrap: [BarComponent],
})
export class BarModule {}
