export const DataNavMobile: any = [
  {
    name: 'Home',
    url: '/',
    icon: '',
    children: [],
    routerLink: true,
  },
  // {
  //   name: '+49 (0) 174 78 68 394',
  //   url: 'tel:+491747868394',
  //   icon: 'https://dein-ruf.de/assets/icons/mobilenav/phone-black-18dp.svg',
  //   children: [],
  // },
];
