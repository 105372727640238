import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd,
} from '@angular/router';
import {
  DOCUMENT,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import LocomotiveScroll from 'locomotive-scroll';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from './services/layout.service';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  public location: any;
  public routerSubscription: any;
  public scroll: any;

  public loading = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private layoutService: LayoutService,
  ) {}

  public ngOnInit(): void {
    this.layoutService.loading$.subscribe((next) => {
      this.loading = next;
    });
    this.recallJsFuntions();
  }

  public ngAfterViewInit(): void {}

  public recallJsFuntions(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        $('.loader').fadeIn('slow');
      }
    });
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel,
        ),
      )
      .subscribe((event) => {
        $.getScript('../assets/js/custom.js');
        $('.loader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
