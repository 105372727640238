import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-desktop',
  templateUrl: './navigation-desktop.component.html',
  styleUrls: ['./navigation-desktop.component.scss'],
})
export class NavigationDesktopComponent {
  @ViewChild('navList') navList!: ElementRef;
  @ViewChild('dropdown1') dropdown1?: MatMenuTrigger;
  @ViewChild('dropdown2') dropdown2?: MatMenuTrigger;

  private subscriptions = new Subscription();
  private overlayContainer: any;
  opened = false;

  constructor(@Inject(DOCUMENT) private doc: Document) {}

  toggleMenu(): void {
    this.opened = !this.opened;
  }

  open(menuTrigger: MatMenuTrigger): void {
    Promise.resolve()
      .then(() => {
        if (!menuTrigger.menuOpen) {
          this.dropdown1?.closeMenu();
          this.dropdown2?.closeMenu();
          menuTrigger.openMenu();
        }
      })
      .then(() => {
        this.overlayContainer = this.doc.querySelector('.scrim');
        this.overlayContainer.classList.add('display-block--important');
        this.overlayContainer.classList.add('z-index-999--important');
        if (this.overlayContainer) {
          this.subscriptions.add(
            fromEvent(this.overlayContainer, 'mouseenter')
              .pipe()
              .subscribe(() => {
                setTimeout(() => {
                  this.dropdown1?.closeMenu();
                  this.dropdown2?.closeMenu();
                  this.overlayContainer.classList.remove(
                    'display-block--important',
                  );
                  this.overlayContainer.classList.remove(
                    'z-index-999--important',
                  );
                }, 350);
              }),
          );
        }
      });
  }

  close(menuTrigger: MatMenuTrigger): void {
    if (menuTrigger.menuOpen) {
      setTimeout(() => {
        menuTrigger.closeMenu();
      }, 1000);
    }
  }

  public call(url: string): void {
    this.doc.defaultView.open(url);
    // const callback = () => {
    //   if (url) {
    //     console.log('callback');
    //     this.doc.defaultView.open(url);
    //   }
    //   return true;
    // };
    // gtag('event', 'conversion', {
    //   'send_to': 'AW-710486452/Klc6CNqI__oBELTT5NIC',
    //   'event_callback': callback,
    // });
    // return true;
  }
}
