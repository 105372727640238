<!-- <section class="privacy-area">
	<div class="container">

		<section #privacySectionStart id="privacySectionStart">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h1>
							Datenschutzerklärung
						</h1>

						<p>
							Wir schützen Ihre Privatsphäre und Ihre privaten Daten. Wir erheben, verarbeiten und nutzen Ihre personenbezogenen Daten in Übereinstimmung mit dem Inhalt dieser Datenschutzerklärung sowie den anwendbaren deutschen Datenschutzgesetzen.
						</p>

						<p>
							In diesen Datenschutzbestimmungen wird geregelt, in welchem Umfang wir personenbezogene Daten über Sie erheben, verarbeiten und nutzen. Wir bitten Sie daher, die nachfolgenden Ausführungen sorgfältig durchzulesen.
						</p>

						<p>
							Diese Erklärung zum Datenschutz gilt ausschließlich für unseren eigenen Internetauftritt. Die Internetseiten in diesem Auftritt können Links auf Internetseiten Dritter enthalten. Auf diese Internetseiten erstreckt sich unsere Datenschutzerklärung nicht. Wenn Sie unseren Internetauftritt verlassen, wird empfohlen, die Datenschutzrichtlinie jeder Website, die personenbezogene Daten sammelt, sorgfältig zu lesen.
						</p>

						<p>
							Mit der Zustimmung zu der nachfolgenden Datenschutzerklärung willigen Sie uns gegenüber in die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unter Beachtung der Datenschutzgesetze und den nachfolgenden Bestimmungen ein.
						</p>

					</div>
				</div>
			</div>
		</section>

		<!~~ I. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten ~~>
		<section #privacySection1 id="privacySection1">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">I.</span> Kontakt
						</h3>

						<span class="subheading subheading--h3">
							Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten
						</span>

						<p>
							Diese Datenschutzinformation gilt für die Datenverarbeitung durch den verantwortlichen:
						</p>

						<p>
							Buket Temiz<br>
							Tiergartenstraße 35, 47533 Kleve, Deutschland<br>
							Email: <a href="mailto:info@btbeauty.de" target="_blank">info@btbeauty.de</a><br>
							Telefon: <a href="tel:+491747868394" target="_blank">+49 (0) 174 78 68 394</a>
						</p>

					</div>
				</div>
			</div>
		</section>

		<!~~ II. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung ~~>
		<section #privacySection2 id="privacySection2">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">II.</span> Datenspeicherung
						</h3>

						<span class="subheading subheading--h3">
							Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung
						</span>

						<div class="sub-section">

							<h4>
								<span class="list-numbering list-numbering--numbers">1.</span> Beim Besuch der Website
							</h4>

							<p>
								Beim Aufrufen unserer Website btbeauty.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
							</p>

							<ul>
								<li>IP-Adresse des anfragenden Rechners,</li>
								<li>Datum und Uhrzeit des Zugriffs,</li>
								<li>Name und URL der abgerufenen Datei,</li>
								<li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
								<li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
							</ul>

							<p>
								Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
							</p>

							<ul>
								<li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
								<li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
								<li>Auswertung der Systemsicherheit und -stabilität sowie</li>
								<li>zu weiteren administrativen Zwecken.</li>
							</ul>

							<p>
								Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
							</p>

							<p>
								Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.
							</p>

						</div>

						<div class="sub-section">

							<h4>
								<span class="list-numbering list-numbering--numbers">2.</span> Bei Nutzung unseres Kontaktformulars und WhatsApp
							</h4>

							<p>
								Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
							</p>

							<p>
								Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.
							</p>

							<p>
								Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage innerhalb der gesetzlichen Regeln für Anwälte gelöscht.
							</p>

						</div>

						<div class="sub-section">

							<h4>
								<span class="list-numbering list-numbering--numbers">3.</span> WhatsApp Business
							</h4>

							<p>
								Sie können uns auch per WhatsApp Business erreichen:
							</p>

							<p>
								<strong>WhatsApp</strong>: Instant-Messaging-Dienst<br>
								<strong>Dienstanbieter</strong>: WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland<br>
								<strong>Website</strong>: https://www.whatsapp.com/?lang=de<br>
								<strong>Datenschutzerklärung</strong>: https://www.whatsapp.com/privacy<br>
								<strong>Opt-out</strong>: https://www.whatsapp.com/legal/#key-updates<br>
							</p>

							<p>
								Bei der Installation von WhatsApp haben Sie gegenüber WhatsApp zugestimmt, dass folgende Daten in den USA gespeichert werden:
							</p>

							<ul>
								<li>
									Die eigene Handynummer
								</li>
								<li>
									Die Handynummern der Freunde im Adressbuch
								</li>
								<li>
									Änderungen bei Statusmeldungen
								</li>
								<li>
									Nachrichten, die nicht zugestellt werden können (liegen maximal 30 Tage auf den Servern)
								</li>
								<li>
									Dateien, die per Nachricht verschickt werden
								</li>
								<li>
									Datum und Uhrzeit von zugestellten Nachrichten
								</li>
								<li>
									Die Telefonnummer des Empfängers einer zugestellten Whatsapp-Nachricht.
								</li>
							</ul>

							<p>
								Wir verwenden WhatsApp Business für die Kontaktaufnahme mit dem Kunden. Die von uns verwendete WhatsApp Installation hat keinen Zugriff auf Kontaktdaten Dritter oder Fotos oder anderen Medien auf unserem Telefon. Auf dem Telefon mit der WhatsApp Business Installation werden keine Kontaktdaten von Kunden oder Dritten gespeichert. Das Telefon / die WhatsApp Installation wird im Erstkontakt nur von Buket Temiz verwendet.

							<p>
								Wir nehmen über WhatsApp keinen Kontakt zu Ihnen auf, wenn Sie dem nicht zustimmen und damit einverstanden sind. Wenn Sie uns per WhatsApp anschreiben gilt dies als Zustimmung, dass wir über diesen Kommunikationskanal mit ihnen in Kontakt treten dürfen, es sei denn Sie widersprechen (vgl. Nr. 8 unten) dem oder sie Schreiben uns, dass Sie nur einen Rückruf wünschen. Nehmen Sie keinen Kontakt per WhatsApp mit mir auf, wenn sie nicht möchten, dass WhatsApp die oben genannten Daten in Bezug zu BT Beauty speichert.
							</p>

							<p>
								Die Speicherung der oben genannten Daten bedeutet nicht, dass WhatsApp den Inhalt der Kommunikation ausließt. WhatsApp verschlüsselt die versendeten Inhalte mit einer sogenannten Ende zu Ende Verschlüsselung. Dazu schreibt WhatsApp:
							</p>

							<p>
								Die Ende-zu-Ende-Verschlüsselung von WhatsApp stellt sicher, dass nur du und die Person, mit der du kommunizierst, lesen kann, was gesendet wurde, und niemand dazwischen, nicht einmal WhatsApp. Das wird dadurch erreicht, dass deine Nachrichten mit Schlössern gesichert werden und nur du und der Empfänger die Schlüssel haben, die notwendig sind, um sie zu entschlüsseln und zu lesen. Als weitere Sicherheitsstufe hat jede Nachricht ihr eigenes einmaliges Schloss mit einem eigenen Schlüssel. All dies passiert automatisch: Du musst keine Einstellungen ändern oder spezielle sichere Chats erstellen, um deine Nachrichten sicher zu machen. <strong>Wichtig</strong>: Ende-zu-Ende-Verschlüsselung ist immer aktiv. Es gibt keine Möglichkeit, die Ende-zu-Ende-Verschlüsselung abzuschalten.
							</p>

							<p>
								WhatsApp hat keine Möglichkeit, den Inhalt von Nachrichten einzusehen oder Anrufe abzuhören. Der Grund dafür ist, dass die Ende-zu-Ende-Verschlüsselung und -Entschlüsselung von gesendeten Nachrichten über WhatsApp ausschließlich auf deinem Telefon stattfindet. Bevor die Nachricht dein Telefon verlässt, wird sie mit einem kryptografischen Schloss gesichert zu dem nur der Empfänger den Schlüssel hat. Zudem ändern sich die Schlüssel mit jeder gesendeten Nachricht. Während dieser Prozess im Hintergrund stattfindet, kannst du bestätigen, dass deine Unterhaltungen verschlüsselt sind, indem du die Sicherheitsnummer überprüfst.
							</p>

						</div>

						<div class="sub-section">

							<h4>
								<span class="list-numbering list-numbering--numbers">4.</span> Server- und Domainhosting
							</h4>

							<p>
								Unsere Homepage sowie unsere Domains sind bei Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) und All-Inkl (Hauptstraße 68, 02742 Friedersdorf) gehostet. Die für die Homepage benötigten Dateien oder die Domain liegen also auf einem von den genannten Providern verwalteten, Rechenzentren in Deutschland.
							</p>

							<p>
								Wenn Sie unsere Homepage besuchen, werden verschiedene Logfiles inkl. der IP-Adresse erfasst. Wir haben unsere Homepage jedoch so konzipiert, dass möglichst wenige Daten an den Anbieter weitergeleitet werden. Ausführliche Informationen können Sie bei der Datenschutzerklärung der jeweiligen Provider entnehmen:
							</p>

							<uL>
								<li>
									<a href="https://policies.google.com/privacy?hl=de&gl=de" rel="nofollow" target="_blank">
										Datenschutzerklärung Google Ireland Limited
									</a>
								</li>
								<li>
									<a href="https://all-inkl.com/datenschutzinformationen/" rel="nofollow" target="_blank">
										Datenschutzerklärung All-Inkl
									</a>
								</li>
							</uL>

							<p>
								Die Verwendung der Provider erfolgt gem. Art 6 Abs. 1 lit. f) DSGVO, da wir ein berechtigtes Interesse an einer zuverlässigen Darstellung unseres Webauftrittes sowie an einem Hosting innerhalb Deutschlands haben.
							</p>

							<p>
								Da beim Besuch der Website Nutzungsdaten anfallen, haben wir einen Auftragsverarbeitungsvertrag gem. Art 28 DSGVO mit den Providern geschlossen. Dies ist ein von der DSGVO vorgegebener Vertrag, welcher gewährleistet, dass die Daten ausschließlich nach der Maßgabe der DSGVO und nach unserer Weisung verarbeitet werden.
							</p>

						</div>
					</div>
				</div>
			</div>
		</section>

		<!~~ III. Weitergabe von Daten ~~>
		<section #privacySection3 id="privacySection3">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">III.</span> Weitergabe von Daten
						</h3>

						<p>
							Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
						</p>

						<p>
							Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
						</p>

						<p>
							Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben, die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben, für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
						</p>

					</div>
				</div>
			</div>
		</section>

		<!~~ IV. Cookies ~~>
		<section #privacySection4 id="privacySection4">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">IV.</span> Cookies
						</h3>

						<p>
							Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
						</p>

						<p>
							In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.
						</p>

						<p>
							Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
						</p>

						<p>
							Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
						</p>

						<p>
							Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.
						</p>

						<p>
							Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
						</p>

						<p>
							Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.
						</p>

					</div>
				</div>
			</div>
		</section>

		<!~~ V. Analyse-Tools ~~>
		<section #privacySection5 id="privacySection5">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">V.</span> Analyse-Tools
						</h3>

						<div class="sub-section">

							<h4>
								<span class="list-numbering list-numbering--numbers">1.</span> Tracking-Tools
							</h4>

							<p>
								Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Website sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.
							</p>

							<p>
								Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.
							</p>

							<div class="sub-section">

								<h5>
									<span class="list-numbering list-numbering--lowercase-letters">1a.</span> Google Analytics
								</h5>

								<p>
									Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der Google Inc. (https://www.google.de/intl/de/about/) (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie
								</p>

								<ul>
									<li>
										Browser-Typ/-Version,
									</li>
									<li>
										verwendetes Betriebssystem,
									</li>
									<li>
										Referrer-URL (die zuvor besuchte Seite),
									</li>
									<li>
										Hostname des zugreifenden Rechners (IP-Adresse),
									</li>
									<li>
										Uhrzeit der Serveranfrage,
									</li>
								</ul>

								<p>
									werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
								</p>

								<p>
									Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern, wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.
								</p>

								<p>
									Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on herunterladen und installieren (https://tools.google.com/dlpage/gaoptout?hl=de).
								</p>

								<p>
									Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
								</p>

								<p>
									Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (https://support.google.com/analytics/answer/6004245?hl=de).
								</p>

							</div>

							<div class="sub-section">

								<h5>
									<span class="list-numbering list-numbering--lowercase-letters">1b.</span> Google Adwords Conversion Tracking
								</h5>

								<p>
									Um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unserer Website für Sie auszuwerten, nutzen wir ferner das Google Conversion Tracking. Dabei wird von Google Adwords ein Cookie (siehe Ziffer 4) auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf unsere Website gelangt sind.
								</p>

								<p>
									Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Website des Adwords-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
								</p>

								<p>
									Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websiten von Adwords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
								</p>

								<p>
									Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. Googles Datenschutzbelehrung zum Conversion-Tracking finden Sie hier (https://services.google.com/sitestats/de.html).
								</p>

							</div>

							<div class="sub-section">

								<h5>
									<span class="list-numbering list-numbering--lowercase-letters">1c.</span> ClickCease Schutzservicesoftware
								</h5>

								<p>
									Wir nutzen auf unserer Website die Funktionen des Werbeanalysedienstes Clickcease (Karniboo Technologies Limited dba ClickCease, 26th Eliphelet Street, Tel Aviv, Israel). Der Anbieter erhebt, speichert und verarbeitet Informationen, die Ihr Browser automatisch übermittelt. Das sind insbesondere der Browsertyp und die Browserversion, das verwendete Betriebssystem, die Referrer-URL, der Hostname des zugreifenden Rechners, die Uhrzeit der Serveranfrage und die IP-Adresse. Außerdem wird Ihre Aufenthaltsdauer auf unseren Seiten, die Anzahl der Seitenaufrufe sowie genutzte Suchwörter gespeichert.
								</p>

								<p>
									Rechtsgrundlage für die Nutzung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
								</p>

								<p>
									Der Anbieter des Webtools analysiert diese Daten nach auffälligem Verhalten in Bezug auf Klickbetrug, also die Häufigkeit von Werbeaufrufen einer IP. Die erhobenen Daten werden u.U. außerhalb der europäischen Union verarbeitet und gespeichert.
								</p>

								<p>
									Nähere Informationen und die Datenschutzbestimmungen können unter der folgenden Adresse abgerufen werden: https://www.clickcease.com/privacy.html.
								</p>

								<p>
									Informationen zur DSGVO / GDPR finden Sie hier: https://docs.clickcease.com/pricing-privacy-and-terms/the-clickcease-roadmap-to-gdpr-compliance
								</p>

							</div>

						</div>

					</div>
				</div>
			</div>
		</section>

		<!~~ VI. Onlinepräsenzen in sozialen Medien ~~>
		<section #privacySection6 id="privacySection6">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">VI.</span> Onlinepräsenzen in sozialen Medien
						</h3>

						<p>
							Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können.
						</p>

						<p>
							Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden könnte.
						</p>

						<p>
							Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
						</p>

						<p>
							Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die vorbeschriebene Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
						</p>

						<p>
							Im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
						</p>

						<p>
							Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.
						</p>

						<div class="sub-section" style="grid-gap: 0 !important;">

							<div style="display: block;">
								<strong>Facebook</strong>: Soziales Netzwerk
							</div>

							<div class="small">

								<div style="display: block;">
									<strong>Dienstanbieter</strong>: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA
								</div>

								<div style="display: block;">
									<strong>Website</strong>: https://www.facebook.com
								</div>

								<div style="display: block;">
									<strong>Datenschutzerklärung</strong>: https://www.facebook.com/about/privacy
								</div>

								<div style="display: block;">
									<strong>Opt-Out</strong>: https://www.facebook.com/settings?tab=ads und https://www.youronlinechoices.com
								</div>

								<div style="display: block;">
									<strong>Einstellungen für Werbeanzeigen</strong>: https://www.facebook.com/settings?tab=ads
								</div>

								<div style="display: block;">
									<strong>Zusätzliche Hinweise zum Datenschutz:</strong>
								</div>

								<div style="display: block;">
									<strong>Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten auf FacebookSeiten</strong>: https://www.facebook.com/legal/terms/page_controller_addendum
								</div>

								<div style="display: block;">
									<strong>Datenschutzhinweise für Facebook-Seiten</strong>: https://www.facebook.com/legal/terms/information_about_page_insights_data
								</div>

							</div>

						</div>

						<div class="sub-section" style="grid-gap: 0 !important;">

							<div style="display: block;">
								<strong>YouTube</strong>: Videoportal
							</div>

							<div class="small">

								<div style="display: block;">
									<strong>Dienstanbieter</strong>: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
								</div>

								<div style="display: block;">
									<strong>Website</strong>: https://www.youtube.com/?gl=DE
								</div>

								<div style="display: block;">
									<strong>Datenschutzerklärung</strong>: https://policies.google.com/privacy
								</div>

								<div style="display: block;">
									<strong>Opt-Out</strong>: https://adssettings.google.com/authenticated
								</div>

								<div>
									<p>
										Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out: https://adssettings.google.com/authenticated.
									</p>
								</div>

							</div>

						</div>

						<div class="sub-section" style="grid-gap: 0 !important;">

							<div style="display: block;">
								<strong>Instagram</strong>: Soziales Netzwerk
							</div>

							<div class="small">

								<div style="display: block;">
									<strong>Dienstanbieter</strong>: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA
								</div>

								<div style="display: block;">
									<strong>Website</strong>: https://www.instagram.com
								</div>

								<div style="display: block;">
									<strong>Datenschutzerklärung</strong>: https://instagram.com/about/legal/privacy
								</div>

								<div style="display: block;">
									<strong>Opt-Out</strong>: https://help.instagram.com/196883487377501/?hel- und https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
								</div>

							</div>

						</div>

						<div class="sub-section" style="grid-gap: 0 !important;">

							<div style="display: block;">
								<strong>Zoom</strong>: Tool zur Online-Kommunikation
							</div>

							<div class="small">

								<div style="display: block;">
									<strong>Dienstanbieter</strong>: 55 Almaden Boulevard, 6th Floor, San Jose, CA 95113
								</div>

								<div style="display: block;">
									<strong>Website</strong>: https://zoom.us/de-de/meetings.html
								</div>

								<div style="display: block;">
									<strong>Datenschutzerklärung</strong>: https://zoom.us/docs/de-de/privacy-and-legal.html
								</div>

								<div style="display: block;">
									<strong>Opt-Out</strong>: https://zoom.us/de-de/gdpr.html
								</div>

							</div>

						</div>

						<div class="sub-section" style="grid-gap: 0 !important;">

							<div style="display: block;">
								<strong>WhatsApp</strong>: Instant-Messaging-Dienst
							</div>

							<div class="small">

								<div style="display: block;">
									<strong>Dienstanbieter</strong>: WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
								</div>

								<div style="display: block;">
									<strong>Website</strong>: https://www.whatsapp.com/?lang=de
								</div>

								<div style="display: block;">
									<strong>Datenschutzerklärung</strong>: https://www.whatsapp.com/privacy
								</div>

								<div style="display: block;">
									<strong>Opt-Out</strong>: https://www.whatsapp.com/legal/#key-updates
								</div>

							</div>

						</div>

					</div>
				</div>
			</div>
		</section>

		<!~~ VII. Betroffenenrechte ~~>
		<section #privacySection7 id="privacySection7">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">VII.</span> Betroffenenrechte
						</h3>

						<p>
							Sie haben das Recht:
						</p>

						<ul>

							<li>
								<p>
									<strong>gemäß Art. 15 DSGVO</strong> Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen,
								</p>
							</li>

							<li>
								<p>
									<strong>gemäß Art. 16 DSGVO</strong> unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
								</p>
							</li>

							<li>
								<p>
									<strong>gemäß Art. 17 DSGVO</strong> die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist,
								</p>
							</li>

							<li>
								<p>
									<strong>gemäß Art. 18 DSGVO</strong> die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben,
								</p>
							</li>

							<li>
								<p>
									<strong>gemäß Art. 20 DSGVO</strong> Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen,
								</p>
							</li>

							<li>
								<p>
									<strong>gemäß Art. 7 Abs. 3 DSGVO</strong> Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
								</p>
							</li>

							<li>
								<p>
									<strong>gemäß Art. 77 DSGVO</strong> sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes wenden.
								</p>
							</li>

						</ul>

					</div>
				</div>
			</div>
		</section>

		<!~~ VIII. Widerspruchsrecht ~~>
		<section #privacySection8 id="privacySection8">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">VIII.</span> Widerspruchsrecht
						</h3>

						<p>
							Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von mir umgesetzt wird.
						</p>

						<p>
							Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <a href="mailto:info@btbeauty.de" target="_blank">info@btbeauty.de</a> oder wenden Sie sich mit dem Stichwort „Datenschutz“ an:
						</p>

						<address>
							Buket Temiz<br>
							Tiergartenstraße 35<br>
							47533 Kleve<br>
							Deutschland
						</address>

					</div>
				</div>
			</div>
		</section>

		<!~~ IX. Datensicherheit ~~>
		<section #privacySection9 id="privacySection9">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">IX.</span> Datensicherheit
						</h3>

						<p>
							Ich verwende innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite meines Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
						</p>

						<p>
							Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
						</p>

					</div>
				</div>
			</div>
		</section>

		<!~~ X. Aktualität und Änderung dieser Datenschutzerklärung ~~>
		<section #privacySection10 id="privacySection10">
			<div class="section-content">
				<div class="grid">
					<div class="grid-item">

						<h3>
							<span class="list-numbering list-numbering--roman">X.</span> Aktualität und Änderung dieser Datenschutzerklärung
						</h3>

						<p>
							Diese Datenschutzerklärung ist aktuell gültig und hat den Stand September 2022.
						</p>

						<p>
							Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website von Ihnen abgerufen und ausgedruckt werden.
						</p>

					</div>
				</div>
			</div>
		</section>

	</div>
</section> -->

<!-- <div class="page-title-area title-img-one">
    <!~~ <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Privacy Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div> ~~>
</div> -->

<section class="privacy-area">
    <div class="container">

			<section #privacySectionStart id="privacySectionStart">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h1>
								Datenschutzerklärung
							</h1>

							<p>
								Ich schützen Ihre Privatsphäre und Ihre privaten Daten. Ich erhebe, verarbeite und nutze Ihre personenbezogenen Daten in Übereinstimmung mit dem Inhalt dieser Datenschutzerklärung sowie den anwendbaren deutschen Datenschutzgesetzen.
							</p>

							<p>
								In diesen Datenschutzbestimmungen wird geregelt, in welchem Umfang ich personenbezogene Daten über Sie erhebe, verarbeite und nutze. Ich bitte Sie daher, die nachfolgenden Ausführungen sorgfältig durchzulesen.
							</p>

							<p>
								Diese Erklärung zum Datenschutz gilt ausschließlich für meinen eigenen Internetauftritt. Die Internetseiten in diesem Auftritt können Links auf Internetseiten Dritter enthalten. Auf diese Internetseiten erstreckt sich meine Datenschutzerklärung nicht. Wenn Sie meinen Internetauftritt verlassen, wird empfohlen, die Datenschutzrichtlinie jeder Website, die personenbezogene Daten sammelt, sorgfältig zu lesen.
							</p>

							<p>
								Mit der Zustimmung zu der nachfolgenden Datenschutzerklärung willigen Sie mir gegenüber in die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unter Beachtung der Datenschutzgesetze und den nachfolgenden Bestimmungen ein.
							</p>

						</div>
					</div>
				</div>
			</section>

			<!-- I. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten -->
			<section #privacySection1 id="privacySection1">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">I.</span> Kontakt
							</h3>

							<span class="subheading subheading--h3">
								Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten
							</span>

							<p>
								Diese Datenschutzinformation gilt für die Datenverarbeitung durch die verantwortliche:
							</p>

							<p>
								Buket Temiz<br>
								Tiergartenstraße 35, 47533 Kleve, Deutschland<br>
								Email: <a href="mailto:info@btbeauty.de" target="_blank">info@btbeauty.de</a><br>
								Telefon: <a href="tel:+491747868394" target="_blank">+49 (0) 174 78 68 394</a>
							</p>

						</div>
					</div>
				</div>
			</section>

			<!-- II. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung -->
			<section #privacySection2 id="privacySection2">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">II.</span> Datenspeicherung
							</h3>

							<span class="subheading subheading--h3">
								Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung
							</span>

							<div class="sub-section">

								<h4>
									<span class="list-numbering list-numbering--numbers">1.</span> Beim Besuch der Website
								</h4>

								<p>
									Beim Aufrufen meiner Website btbeauty.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server meiner Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
								</p>

								<ul>
									<li>IP-Adresse des anfragenden Rechners,</li>
									<li>Datum und Uhrzeit des Zugriffs,</li>
									<li>Name und URL der abgerufenen Datei,</li>
									<li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
									<li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
								</ul>

								<p>
									Die genannten Daten werden durch mir zu folgenden Zwecken verarbeitet:
								</p>

								<ul>
									<li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
									<li>Gewährleistung einer komfortablen Nutzung meiner Website,</li>
									<li>Auswertung der Systemsicherheit und -stabilität sowie</li>
									<li>zu weiteren administrativen Zwecken.</li>
								</ul>

								<p>
									Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Mein berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwende ich die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
								</p>

								<p>
									Darüber hinaus setze ich beim Besuch meiner Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.
								</p>

							</div>

							<div class="sub-section">

								<h4>
									<span class="list-numbering list-numbering--numbers">2.</span> Bei Nutzung des Kontaktformulars und WhatsApp
								</h4>

								<p>
									Bei Fragen jeglicher Art biete ich Ihnen die Möglichkeit, mit mir über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse / Telefonnummer erforderlich, damit ich weiß, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
								</p>

								<p>
									Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit mir erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.
								</p>

							</div>

							<div class="sub-section">

								<h4>
									<span class="list-numbering list-numbering--numbers">3.</span> WhatsApp
								</h4>

								<p>
									Sie können mich auch per WhatsApp erreichen:
								</p>

								<p>
									<strong>WhatsApp</strong>: Instant-Messaging-Dienst<br>
									<strong>Dienstanbieter</strong>: WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland<br>
									<strong>Website</strong>: <a href="https://www.whatsapp.com/?lang=de" target="_blank" rel="nofollow">https://www.whatsapp.com/?lang=de</a><br>
									<strong>Datenschutzerklärung</strong>: <a href="https://www.whatsapp.com/privacy" rel="nofollow" target="_blank">https://www.whatsapp.com/privacy</a><br>
									<strong>Opt-out</strong>: <a href="https://www.whatsapp.com/legal/#key-updates" rel="nofollow" target="_blank">https://www.whatsapp.com/legal/#key-updates</a><br>
								</p>

								<p>
									Bei der Installation von WhatsApp haben Sie gegenüber WhatsApp zugestimmt, dass folgende Daten in den USA gespeichert werden:
								</p>

								<ul>
									<li>
										die eigene Handynummer
									</li>
									<li>
										die Handynummern der Freunde im Adressbuch
									</li>
									<li>
										Änderungen bei Statusmeldungen
									</li>
									<li>
										Nachrichten, die nicht zugestellt werden können (liegen maximal 30 Tage auf den Servern)
									</li>
									<li>
										Dateien, die per Nachricht verschickt werden
									</li>
									<li>
										Datum und Uhrzeit von zugestellten Nachrichten
									</li>
									<li>
										die Telefonnummer des Empfängers einer zugestellten Whatsapp-Nachricht.
									</li>
								</ul>

								<p>
									Ich verwenden WhatsApp für die Kontaktaufnahme mit dem Kunden. Die von mir verwendete WhatsApp Installation hat keinen Zugriff auf Kontaktdaten Dritter oder Fotos oder anderen Medien auf meinem Telefon. Auf dem Telefon mit der WhatsApp Installation werden keine Kontaktdaten von Kunden oder Dritten gespeichert. Das Telefon / die WhatsApp Installation wird im Erstkontakt nur von Buket Temiz verwendet.

								<p>
									Ich nehme über WhatsApp keinen Kontakt zu Ihnen auf, wenn Sie dem nicht zustimmen und damit einverstanden sind. Wenn Sie mich per WhatsApp anschreiben gilt dies als Zustimmung, dass ich über diesen Kommunikationskanal mit ihnen in Kontakt treten darf, es sei denn Sie widersprechen (vgl. Nr. 8 unten) dem oder sie Schreiben mir, dass Sie nur einen Rückruf wünschen. Nehmen Sie keinen Kontakt per WhatsApp mit mir auf, wenn sie nicht möchten, dass WhatsApp die oben genannten Daten in Bezug zu BT Beauty speichert.
								</p>

								<p>
									Die Speicherung der oben genannten Daten bedeutet nicht, dass WhatsApp den Inhalt der Kommunikation ausließt. WhatsApp verschlüsselt die versendeten Inhalte mit einer sogenannten Ende zu Ende Verschlüsselung.
								</p>

							</div>

							<div class="sub-section">

								<h4>
									<span class="list-numbering list-numbering--numbers">4.</span> Server- & Domainhosting
								</h4>

								<p>
									Meine Homepage sowie meine Domains sind bei Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) und All-Inkl (Hauptstraße 68, 02742 Friedersdorf) gehostet. Die für die Homepage benötigten Dateien oder die Domain liegen also auf einem von den genannten Providern verwalteten Rechenzentren in Deutschland.
								</p>

								<p>
									Wenn Sie meine Homepage besuchen, werden verschiedene Logfiles inkl. der IP-Adresse erfasst. Ich haben meine Homepage jedoch so konzipiert, dass möglichst wenige Daten an den Anbieter weitergeleitet werden. Ausführliche Informationen können Sie bei der Datenschutzerklärung der jeweiligen Provider entnehmen:
								</p>

								<uL>
									<li>
										<a href="https://policies.google.com/privacy?hl=de&gl=de" rel="nofollow" target="_blank">
											Datenschutzerklärung Google Ireland Limited
										</a>
									</li>
									<li>
										<a href="https://all-inkl.com/datenschutzinformationen/" rel="nofollow" target="_blank">
											Datenschutzerklärung All-Inkl
										</a>
									</li>
								</uL>

								<p>
									Die Verwendung der Provider erfolgt gem. Art 6 Abs. 1 lit. f) DSGVO, da ich ein berechtigtes Interesse an einer zuverlässigen Darstellung meines Webauftrittes sowie an einem Hosting innerhalb Deutschlands habe.
								</p>

								<p>
									Da beim Besuch der Website Nutzungsdaten anfallen, habe ich einen Auftragsverarbeitungsvertrag gem. Art 28 DSGVO mit den Providern geschlossen. Dies ist ein von der DSGVO vorgegebener Vertrag, welcher gewährleistet, dass die Daten ausschließlich nach der Maßgabe der DSGVO und nach meiner Weisung verarbeitet werden.
								</p>

							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- III. Weitergabe von Daten -->
			<section #privacySection3 id="privacySection3">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">III.</span> Weitergabe von Daten
							</h3>

							<p>
								Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
							</p>

							<p>
								Ich geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
							</p>

							<p>
								Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben, die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben, für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
							</p>

						</div>
					</div>
				</div>
			</section>

			<!-- IV. Cookies -->
			<section #privacySection4 id="privacySection4">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">IV.</span> Cookies
							</h3>

							<p>
								Ich setze auf meiner Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie meine Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
							</p>

							<p>
								In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass ich dadurch unmittelbar Kenntnis von Ihrer Identität erhalte.
							</p>

							<p>
								Der Einsatz von Cookies dient einerseits dazu, die Nutzung meines Angebots für Sie angenehmer zu gestalten. So setze ich sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten meiner Website bereits besucht haben. Diese werden nach Verlassen meiner Seite automatisch gelöscht.
							</p>

							<p>
								Darüber hinaus setze ich ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie meine Seite erneut, um meine Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei mir waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
							</p>

							<p>
								Zum anderen setzte ich Cookies ein, um die Nutzung meiner Website statistisch zu erfassen und zum Zwecke der Optimierung meines Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es mir, bei einem erneuten Besuch meiner Seite automatisch zu erkennen, dass Sie bereits bei mir waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.
							</p>

							<p>
								Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung meiner berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
							</p>

							<p>
								Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen meiner Website nutzen können.
							</p>

						</div>
					</div>
				</div>
			</section>

			<!-- V. Analyse-Tools -->
			<section #privacySection5 id="privacySection5">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">V.</span> Analyse-Tools
							</h3>

							<div class="sub-section">

								<h4>
									<span class="list-numbering list-numbering--numbers">1.</span> Tracking-Tools
								</h4>

								<p>
									Die im Folgenden aufgeführten und von mir eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen will ich eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung meiner Website sicherstellen. Zum anderen setze ich die Tracking-Maßnahmen ein, um die Nutzung meiner Website statistisch zu erfassen und zum Zwecke der Optimierung meines Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.
								</p>

								<p>
									Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.
								</p>

								<div class="sub-section">

									<h5>
										<span class="list-numbering list-numbering--lowercase-letters">1a.</span> Google Analytics
									</h5>

									<p>
										Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung meiner Seiten nutzen ich Google Analytics, ein Webanalysedienst der Google Inc. (<a href="https://www.google.de/intl/de/about/" rel="nofollow" target="_blank">https://www.google.de/intl/de/about/</a>) (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie
									</p>

									<ul>
										<li>
											Browser-Typ/-Version,
										</li>
										<li>
											verwendetes Betriebssystem,
										</li>
										<li>
											Referrer-URL (die zuvor besuchte Seite),
										</li>
										<li>
											Hostname des zugreifenden Rechners (IP-Adresse),
										</li>
										<li>
											Uhrzeit der Serveranfrage,
										</li>
									</ul>

									<p>
										werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
									</p>

									<p>
										Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern, ich weise jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.
									</p>

									<p>
										Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on herunterladen und installieren (<a href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="nofollow" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>).
									</p>

									<p>
										Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für meine Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
									</p>

									<p>
										Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (<a href="https://support.google.com/analytics/answer/6004245?hl=de" rel="nofollow" target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a>).
									</p>

								</div>

								<div class="sub-section">

									<h5>
										<span class="list-numbering list-numbering--lowercase-letters">1b.</span> Google Adwords Conversion Tracking
									</h5>

									<p>
										Um die Nutzung meiner Website statistisch zu erfassen und zum Zwecke der Optimierung meiner Website für Sie auszuwerten, nutze ich ferner das Google Conversion Tracking. Dabei wird von Google Adwords ein Cookie (siehe Ziffer 4) auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf meiner Website gelangt sind.
									</p>

									<p>
										Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Website des Adwords-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
									</p>

									<p>
										Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websiten von Adwords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
									</p>

									<p>
										Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. Googles Datenschutzbelehrung zum Conversion-Tracking finden Sie hier (<a href="https://services.google.com/sitestats/de.html" rel="nofollow" target="_blank">https://services.google.com/sitestats/de.html</a>).
									</p>

								</div>

								<div class="sub-section">

									<h5>
										<span class="list-numbering list-numbering--lowercase-letters">1c.</span> ClickCease Schutzservicesoftware
									</h5>

									<p>
										Ich nutze auf meiner Website die Funktionen des Werbeanalysedienstes Clickcease (Karniboo Technologies Limited dba ClickCease, 26th Eliphelet Street, Tel Aviv, Israel). Der Anbieter erhebt, speichert und verarbeitet Informationen, die Ihr Browser automatisch übermittelt. Das sind insbesondere der Browsertyp und die Browserversion, das verwendete Betriebssystem, die Referrer-URL, der Hostname des zugreifenden Rechners, die Uhrzeit der Serveranfrage und die IP-Adresse. Außerdem wird Ihre Aufenthaltsdauer auf meinen Seiten, die Anzahl der Seitenaufrufe sowie genutzte Suchwörter gespeichert.
									</p>

									<p>
										Rechtsgrundlage für die Nutzung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
									</p>

									<p>
										Der Anbieter des Webtools analysiert diese Daten nach auffälligem Verhalten in Bezug auf Klickbetrug, also die Häufigkeit von Werbeaufrufen einer IP. Die erhobenen Daten werden u.U. außerhalb der europäischen Union verarbeitet und gespeichert.
									</p>

									<p>
										Nähere Informationen und die Datenschutzbestimmungen können unter der folgenden Adresse abgerufen werden: <a href="https://www.clickcease.com/privacy.html" rel="nofollow" target="_blank">https://www.clickcease.com/privacy.html</a>.
									</p>

									<p>
										Informationen zur DSGVO / GDPR finden Sie hier: <a href="https://docs.clickcease.com/pricing-privacy-and-terms/the-clickcease-roadmap-to-gdpr-compliance" rel="nofollow" target="_blank">https://docs.clickcease.com/pricing-privacy-and-terms/the-clickcease-roadmap-to-gdpr-compliance</a>
									</p>

								</div>

							</div>

						</div>
					</div>
				</div>
			</section>

			<!-- VI. Onlinepräsenzen in sozialen Medien -->
			<section #privacySection6 id="privacySection6">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">VI.</span> Onlinepräsenzen in sozialen Medien
							</h3>

							<p>
								Ich unterhalte Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Nutzern kommunizieren und sie dort über meine Leistungen informieren zu können.
							</p>

							<p>
								Ich weise darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden könnte.
							</p>

							<p>
								Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
							</p>

							<p>
								Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage meiner berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die vorbeschriebene Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
							</p>

							<p>
								Im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weise ich darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an mich wenden.
							</p>

							<p>
								Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out), verweise ich auf die nachfolgend verlinkten Angaben der Anbieter.
							</p>

							<div class="sub-section" style="grid-gap: 0 !important;">

								<div style="display: block;">
									<strong>Facebook</strong>: Soziales Netzwerk
								</div>

								<div class="small">

									<div style="display: block;">
										<strong>Dienstanbieter</strong>: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA
									</div>

									<div style="display: block;">
										<strong>Website</strong>: <a href="https://www.facebook.com" rel="nofollow" target="_blank">https://www.facebook.com</a>
									</div>

									<div style="display: block;">
										<strong>Datenschutzerklärung</strong>: <a href="https://www.facebook.com/about/privacy" rel="nofollow" target="_blank">https://www.facebook.com/about/privacy</a>
									</div>

									<div style="display: block;">
										<strong>Opt-Out</strong>: <a href="https://www.facebook.com/settings?tab=ads" rel="nofollow" target="_blank">https://www.facebook.com/settings?tab=ads</a> und <a href="https://www.youronlinechoices.com" rel="nofollow" target="_blank">https://www.youronlinechoices.com</a>
									</div>

									<div style="display: block;">
										<strong>Einstellungen für Werbeanzeigen</strong>: <a href="https://www.facebook.com/settings?tab=ads" rel="nofollow" target="_blank">https://www.facebook.com/settings?tab=ads</a>
									</div>

									<div style="display: block;">
										<strong>Zusätzliche Hinweise zum Datenschutz:</strong>
									</div>

									<div style="display: block;">
										<strong>Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten auf FacebookSeiten</strong>: <a href="https://www.facebook.com/legal/terms/page_controller_addendum" rel="nofollow" target="_blank">https://www.facebook.com/legal/terms/page_controller_addendum</a>
									</div>

									<div style="display: block;">
										<strong>Datenschutzhinweise für Facebook-Seiten</strong>: <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data" rel="nofollow" target="_blank">https://www.facebook.com/legal/terms/information_about_page_insights_data</a>
									</div>

								</div>

							</div>

							<div class="sub-section" style="grid-gap: 0 !important;">

								<div style="display: block;">
									<strong>YouTube</strong>: Videoportal
								</div>

								<div class="small">

									<div style="display: block;">
										<strong>Dienstanbieter</strong>: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
									</div>

									<div style="display: block;">
										<strong>Website</strong>: <a href="https://www.youtube.com/?gl=DE" rel="nofollow" target="_blank">https://www.youtube.com/?gl=DE</a>
									</div>

									<div style="display: block;">
										<strong>Datenschutzerklärung</strong>: <a href="https://policies.google.com/privacy" rel="nofollow" target="_blank">https://policies.google.com/privacy</a>
									</div>

									<div style="display: block;">
										<strong>Opt-Out</strong>: <a href="https://adssettings.google.com/authenticated" rel="nofollow" target="_blank">https://adssettings.google.com/authenticated</a>
									</div>

									<div>
										<p>
											Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" rel="nofollow" target="_blank">https://www.google.com/policies/privacy/</a>, Opt-Out: <a href="https://adssettings.google.com/authenticated" rel="nofollow" target="_blank">https://adssettings.google.com/authenticated</a>.
										</p>
									</div>

								</div>

							</div>

							<div class="sub-section" style="grid-gap: 0 !important;">

								<div style="display: block;">
									<strong>Instagram</strong>: Soziales Netzwerk
								</div>

								<div class="small">

									<div style="display: block;">
										<strong>Dienstanbieter</strong>: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA
									</div>

									<div style="display: block;">
										<strong>Website</strong>: <a href="https://www.instagram.com" rel="nofollow" target="_blank">https://www.instagram.com</a>
									</div>

									<div style="display: block;">
										<strong>Datenschutzerklärung</strong>: <a href="https://instagram.com/about/legal/privacy" rel="nofollow" target="_blank">https://instagram.com/about/legal/privacy</a>
									</div>

									<div style="display: block;">
										<strong>Opt-Out</strong>: <a href="https://help.instagram.com/196883487377501/?hel-" rel="nofollow" target="_blank">https://help.instagram.com/196883487377501/?hel-</a> und <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" rel="nofollow" target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>
									</div>

								</div>

							</div>

							<div class="sub-section" style="grid-gap: 0 !important;">

								<div style="display: block;">
									<strong>Zoom</strong>: Tool zur Online-Kommunikation
								</div>

								<div class="small">

									<div style="display: block;">
										<strong>Dienstanbieter</strong>: 55 Almaden Boulevard, 6th Floor, San Jose, CA 95113
									</div>

									<div style="display: block;">
										<strong>Website</strong>: <a href="https://zoom.us/de-de/meetings.html" rel="nofollow" target="_blank">https://zoom.us/de-de/meetings.html</a>
									</div>

									<div style="display: block;">
										<strong>Datenschutzerklärung</strong>: <a href="https://zoom.us/docs/de-de/privacy-and-legal.html" rel="nofollow" target="_blank">https://zoom.us/docs/de-de/privacy-and-legal.html</a>
									</div>

									<div style="display: block;">
										<strong>Opt-Out</strong>: <a href="https://zoom.us/de-de/gdpr.html" rel="nofollow" target="_blank">https://zoom.us/de-de/gdpr.html</a>
									</div>

								</div>

							</div>

							<div class="sub-section" style="grid-gap: 0 !important;">

								<div style="display: block;">
									<strong>WhatsApp</strong>: Instant-Messaging-Dienst
								</div>

								<div class="small">

									<div style="display: block;">
										<strong>Dienstanbieter</strong>: WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
									</div>

									<div style="display: block;">
										<strong>Website</strong>: <a href="https://www.whatsapp.com/?lang=de" rel="nofollow" target="_blank">https://www.whatsapp.com/?lang=de</a>
									</div>

									<div style="display: block;">
										<strong>Datenschutzerklärung</strong>: <a href="https://www.whatsapp.com/privacy" rel="nofollow" target="_blank">https://www.whatsapp.com/privacy</a>
									</div>

									<div style="display: block;">
										<strong>Opt-Out</strong>: <a href="https://www.whatsapp.com/legal/#key-updates" rel="nofollow" target="_blank">https://www.whatsapp.com/legal/#key-updates</a>
									</div>

								</div>

							</div>

						</div>
					</div>
				</div>
			</section>

			<!-- VII. Betroffenenrechte -->
			<section #privacySection7 id="privacySection7">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">VII.</span> Betroffenenrechte
							</h3>

							<p>
								Sie haben das Recht:
							</p>

							<ul>

								<li>
									<p>
										<strong>gemäß Art. 15 DSGVO</strong> Auskunft über Ihre von mir verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei mir erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen,
									</p>
								</li>

								<li>
									<p>
										<strong>gemäß Art. 16 DSGVO</strong> unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei mir gespeicherten personenbezogenen Daten zu verlangen,
									</p>
								</li>

								<li>
									<p>
										<strong>gemäß Art. 17 DSGVO</strong> die Löschung Ihrer bei mir gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist,
									</p>
								</li>

								<li>
									<p>
										<strong>gemäß Art. 18 DSGVO</strong> die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und ich die Daten nicht mehr benötige, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben,
									</p>
								</li>

								<li>
									<p>
										<strong>gemäß Art. 20 DSGVO</strong> Ihre personenbezogenen Daten, die Sie mir bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen,
									</p>
								</li>

								<li>
									<p>
										<strong>gemäß Art. 7 Abs. 3 DSGVO</strong> Ihre einmal erteilte Einwilligung jederzeit gegenüber mir zu widerrufen. Dies hat zur Folge, dass ich die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen darf und
									</p>
								</li>

								<li>
									<p>
										<strong>gemäß Art. 77 DSGVO</strong> sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes wenden.
									</p>
								</li>

							</ul>

						</div>
					</div>
				</div>
			</section>

			<!-- VIII. Widerspruchsrecht -->
			<section #privacySection8 id="privacySection8">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">VIII.</span> Widerspruchsrecht
							</h3>

							<p>
								Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von mir umgesetzt wird.
							</p>

							<p>
								Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine Email an <a href="mailto:info@btbeauty.de" target="_blank">info@btbeauty.de</a> oder wenden Sie sich mit dem Stichwort „Datenschutz“ an:
							</p>

							<address>
								Buket Temiz<br>
								Tiergartenstraße 35<br>
								47533 Kleve<br>
								Deutschland
							</address>

						</div>
					</div>
				</div>
			</section>

			<!-- IX. Datensicherheit -->
			<section #privacySection9 id="privacySection9">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">IX.</span> Datensicherheit
							</h3>

							<p>
								Ich verwende innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greife ich stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite meines Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
							</p>

							<p>
								Ich bediene mir im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Meine Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
							</p>

						</div>
					</div>
				</div>
			</section>

			<!-- X. Aktualität und Änderung dieser Datenschutzerklärung -->
			<section #privacySection10 id="privacySection10">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								<span class="list-numbering list-numbering--roman">X.</span> Aktualität und Änderung dieser Datenschutzerklärung
							</h3>

							<p>
								Diese Datenschutzerklärung ist aktuell gültig und hat den Stand September 2022.
							</p>

							<p>
								Durch die Weiterentwicklung meiner Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf meiner Website von Ihnen abgerufen und ausgedruckt werden.
							</p>

						</div>
					</div>
				</div>
			</section>

    </div>
</section>