import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, HostListener } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { shareReplay, take } from 'rxjs/operators';
import { DataNavMobile } from './data';

interface FileNode {
  name: string;
  url: any;
  icon: SafeHtml;
  children: FileNode[];
}

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['./navigation-mobile.component.scss'],
})
export class NavigationMobileComponent {
  opened = false;
  nestedTreeControl: NestedTreeControl<FileNode>;
  nestedDataSource: MatTreeNestedDataSource<FileNode>;
  dataChange: BehaviorSubject<FileNode[]> = new BehaviorSubject<FileNode[]>([]);

  constructor(private http: HttpClient) {
    this.nestedTreeControl = new NestedTreeControl<FileNode>(
      NavigationMobileComponent.getChildren,
    );
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.dataChange.subscribe((data) => (this.nestedDataSource.data = data));
    // console.log(JSON.stringify(content));
    this.dataChange.next(DataNavMobile);
    // this.http
    //   .get<any>('https://dein-ruf.de/data/data-nav-mobile.json')
    //   .pipe(take(1), shareReplay())
    //   .subscribe(
    //     (next: any) => {
    //       this.dataChange.next(next);
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     },
    //     () => {
    //       // console.log('completed');
    //     },
    //   );
  }

  static getChildren(node: FileNode): Observable<FileNode[]> {
    return observableOf(node.children);
  }

  toggleMenu(): void {
    this.opened = !this.opened;
  }

  hasNestedChild(_: number, nodeData: FileNode): number {
    return nodeData.children.length;
  }

  public close(): void {
    this.opened = false;
  }
}
