<button
mat-icon-button
class="menu-toggle"
color="primary"
[class.opened]="opened"
(click)="toggleMenu();">
  <div
  id="navIcon4"
  [class.open]="opened">
    <span></span>
    <span></span>
    <span></span>
  </div>
</button>

<nav
*ngIf="opened && nestedDataSource"
class="mobile-navigation">
  <mat-tree
  class="example-tree"
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl">

    <mat-tree-node
    *matTreeNodeDef="let node">
      <li
      class="mat-tree-node">
        <div
        *ngIf="node.icon"
        class="mobile-nav-icon">
          <img [src]="node.icon">
        </div>
        <ng-container *ngIf="node.routerLink">
          <a [routerLink]="['' + node.url + '']" (click)="close()">
            {{node.name}}
          </a>
        </ng-container>
        <ng-container *ngIf="!node.routerLink">
          <a [href]="node.url">
            {{node.name}}
          </a>
        </ng-container>
      </li>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
      <li>
        <ng-container
        *ngIf="node.routerLink">
          <a [routerLink]="['' + node.url + '']" (click)="close()">
            {{node.name}}
          </a>
        </ng-container>
        <ng-container
        *ngIf="!node.routerLink">
          <a matTreeNodeToggle>
            {{node.name}}
          </a>
        </ng-container>
        <button mat-icon-button
                matTreeNodeToggle>
          <div class="expand-icon">
            <img
            [src]="nestedTreeControl.isExpanded(node) ? 'assets/icons/expand_less-black-18dp.svg' : 'assets/icons/expand_more-black-18dp.svg'">
          </div>
        </button>
        {{node.filename}}
        <ul [class.invisible]="!nestedTreeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet>
          </ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>

  </mat-tree>
</nav>
