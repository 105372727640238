<!-- <app-preloader>
</app-preloader> -->

<header style="z-index: 200;display: block;box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);width: 100vw;height: var(--app-bar-height);position: fixed !important;top: 0;right: 0;left: 0;">
  <app-bar #bar id="barComponent" style="padding-left: var(--app-bar-padding-h);padding-right: var(--app-bar-padding-h);width: var(--max-width);height: var(--app-bar-height);margin: 0 auto;max-width: var(--max-width);display: grid;grid-template-columns: max-content 1fr;grid-template-rows: var(--app-bar-height);grid-auto-flow: column;grid-auto-columns: max-content;justify-items: end;align-items: center;justify-content: center;">
  </app-bar>
</header>

<mat-progress-bar
*ngIf="loading"
mode="indeterminate"
style="display: block;width: 100%;height: 4px;z-index: 300;position: fixed;top: var(--app-bar-height);left: 0;right: 0;">
</mat-progress-bar>

<main class="hidden--mobile hidden--desktop" style="display: block;position: relative;width: 100%;height: calc(100vh - var(--app-bar-height));display: flex;align-items: center;justify-content: center;">
  <p style="font-weight: 500;font-size: 20px;line-height: 28px;">
    Under Construction
  </p>
</main>

<main class="hidden--tablet" style="display: block;position: relative;width: 100%;height: auto;">
  <router-outlet>
  </router-outlet>
</main>

<app-footer class="hidden--tablet">
</app-footer>

<div class="scrim" style="width: 100vw;height: calc(100vh - var(--app-bar-height));position: fixed;display: none;top: var(--app-bar-height);z-index: -1;">
</div>