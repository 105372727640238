<ul #navList>
  <li>
    <a mat-button color="primary" [routerLink]="['/']">
      Home
    </a>
  </li>
  <li>
    <a mat-button color="primary" [routerLink]="['/']">
      Über
    </a>
  </li>
  <li>
    <a mat-button color="primary" [routerLink]="['/']">
      Behandlungen
    </a>
  </li>
  <li>
    <a mat-button color="primary" [routerLink]="['/']">
      Galerie
    </a>
  </li>
  <li>
    <a mat-button color="primary" [routerLink]="['/']">
      Kontakt
    </a>
  </li>
  <!-- <li class="dropdown">
    <a mat-button [matMenuTriggerFor]="ratgeber" #dropdown1="matMenuTrigger" (mouseenter)="open(dropdown1)" overlapTrigger="false" href="#">
       Behandlungen
    </a>
    <mat-menu #ratgeber="matMenu" xPosition="after" [hasBackdrop]="false">
      <span>
        <a mat-menu-item [routerLink]="['/']">
          Microblading
        </a>
        <a mat-menu-item [routerLink]="['/']">
          Permenent Make-up
        </a>
      </span>
    </mat-menu>
  </li> -->
 <!-- <li>
   <a href="https://dein-ruf.de/arzt-bewertungen/" mat-button>Für Ärzte</a>
 </li>
 <li>
   <a href="https://dein-ruf.de/unser-team/" mat-button>Team</a>
 </li>
 <li>
   <a href="https://dein-ruf.de/google-rezensionen-loeschen-videos/" mat-button>Videos</a>
 </li>
 <li>
   <a mat-button href="https://dein-ruf.de/referenzen/">Referenzen</a>
 </li>
 <li>
   <a mat-button href="https://dein-ruf.de/preise/">Preise</a>
 </li>
 <li class="dropdown">
   <a mat-button [matMenuTriggerFor]="ratgeber" #dropdown1="matMenuTrigger" (mouseenter)="open(dropdown1)" overlapTrigger="false" href="#">
      Ratgeber
   </a>
   <mat-menu #ratgeber="matMenu" xPosition="after" [hasBackdrop]="false">
     <span>
       <a mat-menu-item href="https://dein-ruf.de/bewertung-online-pruefen/">Bewertung online prüfen</a>
       <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/tipps-tricks/">Tipps &amp; Tricks</a>
       <a mat-menu-item href="https://dein-ruf.de/faq/">FAQ</a>
       <a mat-menu-item href="https://dein-ruf.de/ratgeber/galilieo-interview/">Galileo Pro7 Interview</a>
       <a mat-menu-item href="https://dein-ruf.de/nachteile-ohne-anwalt/">Nachteile ohne Anwalt</a>
       <a mat-menu-item href="https://dein-ruf.de/warum-zum-anwalt/">Warum zum Anwalt</a>
       <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/hinweise/">Hinweise</a>
       <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/portale-ratgeber/">Portale</a>
       <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/allgemein-ratgeber/">Allgemein</a>
     </span>
   </mat-menu>
 </li>
 <li class="dropdown">
   <a mat-button [matMenuTriggerFor]="portale" #dropdown2="matMenuTrigger" (mouseenter)="open(dropdown2)" overlapTrigger="false" href="#">
      Portale
   </a>
   <mat-menu #portale="matMenu" xPosition="after" [hasBackdrop]="false">
     <span>
       <a mat-menu-item href="https://dein-ruf.de/portale/google-bewertungen-loeschen-lassen/">Google</a>
       <a mat-menu-item href="https://dein-ruf.de/portale/facebook-bewertungen-loeschen-lassen/">Facebook</a>
       <!~~<a
          mat-menu-item
          href="https://dein-ruf.de/kununu-bewertungen-loeschen-lassen/"
          >Kununu</a
        >~~>
       <!~~<a
          mat-menu-item
          href="https://dein-ruf.de/portale/jameda-bewertungen-loeschen-lassen/"
          >Jameda</a
        >~~>
       <a mat-menu-item [routerLink]="['kununu-bewertungen-loeschen-lassen']">
          Kununu
       </a>
       <a mat-menu-item [routerLink]="['portale/jameda-bewertungen-loeschen-lassen']">
          Jameda
       </a>
       <a mat-menu-item href="https://dein-ruf.de/portale/yelp-bewertungen-loeschen-lassen/">Yelp</a>
       <a mat-menu-item href="https://dein-ruf.de/trustpilot-bewertungen-loeschen-lassen/">Trustpilot</a>
       <a mat-menu-item href="https://dein-ruf.de/tripadvisor-bewertungen-loeschen-lassen/">Tripadvisor</a>
       <a mat-menu-item href="https://dein-ruf.de/holidaycheck-bewertungen-loeschen-lassen/">HolidayCheck</a>
     </span>
   </mat-menu>
 </li> -->
</ul>

<!-- <a mat-raised-button id="headerButton1" class="header-button" color="primary" href="https://dein-ruf.de/online-auftrag/">
  <img class="header-button__icon" src="assets/icons/check-white-18dp.svg" width="18" height="18">
  <span class="button-text">
    Online Auftrag
  </span>
</a> -->

<button mat-flat-button id="headerButton1" class="header-button" color="accent" (click)="call('tel:+491747868394')">
  <img class="header-button__icon" src="assets/icons/phone-white-18dp.svg" width="18" height="18">
  <span class="button-text">
    0174 78 68 39 4
  </span>
</button>