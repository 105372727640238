import {
  collection,
  addDoc,
  DocumentReference,
  DocumentData,
} from 'firebase/firestore';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { ContactForm } from '../components/pages/home-one/models/contact-form';
import { from, Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private firestore: Firestore) {}

  public homeOneContactFormSubmit(
    data: ContactForm,
  ): Observable<DocumentReference<DocumentData>> {
    const ref = collection(this.firestore, `sent`);

    // admin
    const mail1$ = from(
      addDoc(ref, {
        from: 'info@btbeauty.de',
        to: ['info@btbeauty.de'],
        template: {
          data: {
            emailSubject: data.name
              ? `Benachrichtigung: ${data.name} bittet um Rückruf`
              : `Benachrichtigung: Neue Anfrage von ${data.phone}`,
            name: data.name,
            email: data.email,
            phone: data.phone,
          },
          name: 'home_one_contact_form_admin',
        },
        message: {
          // attachments:
          //   attachmentList && attachmentList.length > 0
          //     ? [
          //         ...attachmentList.map((x) => {
          //           const attachment = {
          //             content: x.content,
          //             encoding: 'base64',
          //             filename: x.name,
          //           };
          //           return attachment;
          //         }),
          //       ]
          //     : [],
        },
      }),
    ).pipe(take(1), shareReplay());

    // client
    // const mail2$ = from(
    //   addDoc(ref, {
    //     from: 'info@dein-ruf.de',
    //     to: [
    //       environment.production ? `${data.personEmail}` : 'info@dein-ruf.de',
    //     ],
    //     template: {
    //       data: {
    //         emailSubject: 'Dein-Ruf.de | Auftragsbestätigung',
    //         personCompany: data.personCompany,
    //         personName: data.personName,
    //         personPhone: data.personPhone,
    //         personEmail: data.personEmail,
    //         personWeb: data.personWeb,
    //         insurancePersonName: data.insurancePersonName,
    //         insuranceName: data.insuranceName ?? '',
    //         insuranceMiscellaneous: data.insuranceMiscellaneous ?? '',
    //         insuranceNumber: data.insuranceNumber,
    //         // insuranceFiles: data.insuranceFiles,
    //         reviewSource: data.reviewSource,
    //         reviewList: data.reviewList,
    //         reviewAmount: data.reviewAmount,
    //         terms: data.terms,
    //         orderType: data.orderType,
    //       },
    //       name: 'place_order_dialog_component_client_01',
    //     },
    //     message: {
    //       attachments:
    //         attachmentList && attachmentList.length > 0
    //           ? [
    //               ...attachmentList.map((x) => {
    //                 const attachment = {
    //                   content: x.content,
    //                   encoding: 'base64',
    //                   filename: x.name,
    //                 };

    //                 return attachment;
    //               }),
    //             ]
    //           : [],
    //     },
    //   }),
    // ).pipe(take(1), shareReplay());

    // return merge(mail1$, mail2$);
    return mail1$;
  }
}
