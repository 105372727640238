import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
})
export class BarComponent {
  @HostBinding('class') public class: string;

  constructor() {}
}
