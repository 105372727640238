import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NavigationMobileComponent } from './navigation-mobile.component';

@NgModule({
  declarations: [NavigationMobileComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    MatTreeModule,
    MatButtonModule,
    HttpClientModule,
  ],
  exports: [NavigationMobileComponent],
  bootstrap: [NavigationMobileComponent],
})
export class NavigationMobileModule {}
