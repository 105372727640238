<section class="imprint-area">
    <div class="container">

			<section #imprintSectionStart id="imprintSectionStart">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h1>
								Impressum
							</h1>

							<p>
								Buket Temiz<br>
								Tiergartenstraße 35<br>
								47533 Kleve<br>
								Deutschland<br><br>
								Email: <a href="mailto:info@btbeauty.de" target="_blank">info@btbeauty.de</a><br>
								Telefon: <a href="tel:+491747868394" target="_blank">+49 (0) 174 78 68 394</a>
							</p>

							<p>
								Räumlicher Geltungsbereich: Europäische Union für das Studio / Büro, die in der Bundesrepublik Deutschland eingerichtet sind oder unterhalten werden.
							</p>

							<p>
								Inhaltlich Verantwortlicher gemäß § 55 Abs. 1 RStV: Buket Temiz, Tiergartenstraße 35, 47533 Kleve, Deutschland.
							</p>

						</div>
					</div>
				</div>

			</section>



			<!-- Disclaimer -->
			<section #imprintSection2 id="imprintSection2">
				<div class="section-content">
					<div class="grid">
						<div class="grid-item">

							<h3>
								Disclaimer
							</h3>

							<div class="sub-section">

								<h4>
									Inhalte dieser Website
								</h4>

								<p>
									Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt, dennoch übernimmt der Anbieter keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte.
								</p>

							</div>

							<div class="sub-section">

								<h4>
									Urheberrecht
								</h4>

								<p>
									Die auf dieser Website verwendeten durch oder für den Anbieter erstellten Inhalte unterliegen dem deutschen Urheberrecht. Jede Form der Vervielfältigung, Bearbeitung, Verbreitung oder Verwendung dieser Inhalte bedarf der vorherigen schriftlichen Genehmigung des Anbieters bzw. des jeweiligen Erstellers. Downloads und Kopien von dieser Website sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
								</p>


							</div>

						</div>
					</div>
				</div>
			</section>

    </div>
</section>