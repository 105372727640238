<!-- <div class="banner-area banner-img-one">

	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-lg-6">
						<div class="banner-item">
							<div class="banner-left">
								<h1>Attorneys Fight For Your Justice</h1>
								<p>Our Squad of Professional Attorneys is devoted to your Progress & to achieve your Goal. Our Law Firm
									supports our clients for various versatile cases and to get justice.</p>
								<a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
							</div>
						</div>
					</div>

					<div class="col-lg-6">
						<div class="banner-item">
							<div class="banner-right">
								<img class="banner-animation" src="assets/img/home-one/3.png" alt="Banner">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="banner-bottom">
			<ul>
				<li><i class="flaticon-leader"></i></li>
				<li>
					<span>100% Legal</span>
					<p>Govt Approved</p>
					<img src="assets/img/home-one/9.png" alt="Shape">
				</li>
			</ul>
		</div>
	</div>

</div> -->

<section id="section01">
	<div class="help-area">
		<div class="container-fluid">

			<div
			data-scroll=""
			data-scroll-delay="0.02"
			data-scroll-speed="6"
			class="custom-scroll-item custom-scroll-item--e1 custom-scroll-item--mobile">
				<svg width="203" height="205" viewBox="0 0 203 205" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip_scroll_item_e1)">
					<path d="M0 2.5H100C126.522 2.5 151.957 13.0357 170.711 31.7894C189.464 50.543 200 75.9784 200 102.5C200 129.022 189.464 154.457 170.711 173.211C151.957 191.964 126.522 202.5 100 202.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					<path d="M0 27.5H100C119.891 27.5 138.968 35.4018 153.033 49.467C167.098 63.5322 175 82.6088 175 102.5C175 122.391 167.098 141.468 153.033 155.533C138.968 169.598 119.891 177.5 100 177.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					<path d="M0 52.5H100C113.261 52.5 125.979 57.7678 135.355 67.1447C144.732 76.5215 150 89.2392 150 102.5C150 115.761 144.732 128.479 135.355 137.855C125.979 147.232 113.261 152.5 100 152.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					<path d="M0 77.5H100C106.63 77.5 112.989 80.1339 117.678 84.8223C122.366 89.5107 125 95.8696 125 102.5C125 109.13 122.366 115.489 117.678 120.178C112.989 124.866 106.63 127.5 100 127.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					</g>
					<defs>
					<clipPath id="clip_scroll_item_e1">
					<rect width="202.5" height="205" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>

			<div
			class="custom-scroll-item custom-scroll-item--e2 custom-scroll-item--mobile">
				<svg width="710" height="938" viewBox="0 0 710 938" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip_scroll_item_e2)">
					<path d="M119.8 937.86C142.89 936.99 164.5 924.5 185.19 911.86C273.8 857.55 371 813.22 440.95 726.6C544.4 598.5 677.13 351.39 699.35 111.38C701.35 89.43 725.4 24.94 692.65 7.47C653.52 -13.4 598.94 15.47 585.58 21.07C408.25 94.86 257.9 183.45 118.8 275.6C42.3999 326.2 6.08993 405.73 2.20993 440.65C-6.49007 519.06 13.8799 587.1 8.20993 665.93C4.03993 723.59 0.879925 783.43 16.8799 837.8C32.8799 892.17 73.2699 939.73 119.8 937.86Z" fill="#E2D0C5"/>
					</g>
					<defs>
					<clipPath id="clip_scroll_item_e2">
					<rect width="709.74" height="937.95" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>

			<div
			data-scroll=""
			data-scroll-delay="0.9"
			data-scroll-speed="3"
			class="custom-scroll-item custom-scroll-item--e1 custom-scroll-item--desktop">
				<svg width="203" height="205" viewBox="0 0 203 205" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip_scroll_item_e1)">
					<path d="M0 2.5H100C126.522 2.5 151.957 13.0357 170.711 31.7894C189.464 50.543 200 75.9784 200 102.5C200 129.022 189.464 154.457 170.711 173.211C151.957 191.964 126.522 202.5 100 202.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					<path d="M0 27.5H100C119.891 27.5 138.968 35.4018 153.033 49.467C167.098 63.5322 175 82.6088 175 102.5C175 122.391 167.098 141.468 153.033 155.533C138.968 169.598 119.891 177.5 100 177.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					<path d="M0 52.5H100C113.261 52.5 125.979 57.7678 135.355 67.1447C144.732 76.5215 150 89.2392 150 102.5C150 115.761 144.732 128.479 135.355 137.855C125.979 147.232 113.261 152.5 100 152.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					<path d="M0 77.5H100C106.63 77.5 112.989 80.1339 117.678 84.8223C122.366 89.5107 125 95.8696 125 102.5C125 109.13 122.366 115.489 117.678 120.178C112.989 124.866 106.63 127.5 100 127.5H0" stroke="#624539" stroke-width="5" stroke-miterlimit="10"/>
					</g>
					<defs>
					<clipPath id="clip_scroll_item_e1">
					<rect width="202.5" height="205" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>

			<div
			data-scroll=""
			data-scroll-delay="0.01"
			data-scroll-speed="-1"
			class="custom-scroll-item custom-scroll-item--e2 custom-scroll-item--desktop">
				<svg width="710" height="938" viewBox="0 0 710 938" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip_scroll_item_e2)">
					<path d="M119.8 937.86C142.89 936.99 164.5 924.5 185.19 911.86C273.8 857.55 371 813.22 440.95 726.6C544.4 598.5 677.13 351.39 699.35 111.38C701.35 89.43 725.4 24.94 692.65 7.47C653.52 -13.4 598.94 15.47 585.58 21.07C408.25 94.86 257.9 183.45 118.8 275.6C42.3999 326.2 6.08993 405.73 2.20993 440.65C-6.49007 519.06 13.8799 587.1 8.20993 665.93C4.03993 723.59 0.879925 783.43 16.8799 837.8C32.8799 892.17 73.2699 939.73 119.8 937.86Z" fill="#E2D0C5"/>
					</g>
					<defs>
					<clipPath id="clip_scroll_item_e2">
					<rect width="709.74" height="937.95" fill="white"/>
					</clipPath>
					</defs>
				</svg>
			</div>

			<div class="row align-items-center">
				<div class="col-lg-6">

					<!-- <div class="custom-image-container custom-image--t1 custom-image--mobile">
						<div
						class="custom-image__border">
						</div>
						<div
						data-scroll=""
						data-scroll-delay="0.1"
						data-scroll-speed="-1"
						class="custom-image__picture">
							<img src="assets/img/home-one/5.jpg" width="420" height="630">
						</div>
					</div> -->

					<div class="custom-image-container custom-image--t1">
						<div
						class="custom-image__border">
						</div>
						<div
						class="custom-image__picture">
							<img draggable="false" src="assets/img/home-one/5.jpg" width="420" height="630">
						</div>
					</div>

				</div>

				<div class="col-lg-6">

					<div class="help-item">
						<div class="help-right">

							<h1>
								BT Beauty
							</h1>

							<p>
								Herzlich willkommen bei BT Beauty. Mein Name ist Buket Temiz, lesen Sie hier mehr über meine Qualifikationen im Permanent Make-up & Microblading.
							</p>

							<div class="help-inner-left">
								<ul>
									<li>
										<i class="flaticon-checkmark"></i> Brow Lifting, Lash Lifting
									</li>
									<li>
										<i class="flaticon-checkmark"></i> Powder Brows
									</li>
								</ul>
							</div>

							<div class="help-inner-right">
								<ul>
									<li>
										<i class="flaticon-checkmark"></i> PhiBrows Microblading
									</li>
									<li>
										<i class="flaticon-checkmark"></i> PhiRemoval, Make-up Entfernung
									</li>
								</ul>
							</div>

							<div class="help-signature">
								<img src="assets/img/home-one/signature_btbeauty.de.png" alt="Signature">
							</div>

							<a mat-flat-button class="mat-btn--1" color="accent" routerLink="/">
								Termin buchen
							</a>

						</div>
					</div>

				</div>
			</div>

		</div>
	</div>
</section>

<!-- <section class="practice-area pt-100 pb-70">

	<div
	class="container">

		<div class="section-title">
			<span>HOW CAN WE HELP YOU</span>
			<h2>Our Legal Practices Areas</h2>
		</div>

		<div class="row">
			<div class="col-sm-6 col-lg-4">
				<div class="practice-item">
					<div class="practice-icon">
						<i class="flaticon-law"></i>
					</div>
					<h3>Civil Law</h3>
					<p>Our attorneys are highly trained and skilled in the Civil Law sector to provide the top service.</p>
					<a routerLink="/practice-details">Read More</a>
					<img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
					<img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
				</div>
			</div>

			<div class="col-sm-6 col-lg-4">
				<div class="practice-item">
					<div class="practice-icon">
						<i class="flaticon-family"></i>
					</div>
					<h3>Family Law</h3>
					<p>Attorney of Our Squad is tremendously skillful to acquire a positive outcome and honest.</p>
					<a routerLink="/practice-details">Read More</a>
					<img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
					<img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
				</div>
			</div>

			<div class="col-sm-6 col-lg-4">
				<div class="practice-item">
					<div class="practice-icon">
						<i class="flaticon-inheritance"></i>
					</div>
					<h3>Business Law</h3>
					<p>You don’t need to worry about your business law till our Master’s Attorneys are here to help you.</p>
					<a routerLink="/practice-details">Read More</a>
					<img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
					<img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
				</div>
			</div>

			<div class="col-sm-6 col-lg-4">
				<div class="practice-item">
					<div class="practice-icon">
						<i class="flaticon-mortarboard"></i>
					</div>
					<h3>Education Law</h3>
					<p>Need Attorneys for Educational law? Then, here our Talented & Professionals Attorneys ready to serve you.
					</p>
					<a routerLink="/practice-details">Read More</a>
					<img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
					<img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
				</div>
			</div>

			<div class="col-sm-6 col-lg-4">
				<div class="practice-item">
					<div class="practice-icon">
						<i class="flaticon-auction"></i>
					</div>
					<h3>Criminal Law</h3>
					<p>We provide the Pre-Eminent Attorneys to solve the tough Criminal cases to help you.</p>
					<a routerLink="/practice-details">Read More</a>
					<img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
					<img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
				</div>
			</div>

			<div class="col-sm-6 col-lg-4">
				<div class="practice-item">
					<div class="practice-icon">
						<i class="flaticon-vulnerability"></i>
					</div>
					<h3>Cyber Law</h3>
					<p>We proffer cyber specialists attorneys who are brilliant in determining these types of cases.</p>
					<a routerLink="/practice-details">Read More</a>
					<img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
					<img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
				</div>
			</div>
		</div>

	</div>

</section> -->

<section>
</section>

<section>
</section>

<section class="hidden--mobile">
</section>

<!-- <section id="section02" class="expertise-area expertise-area--1">

	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-lg-6">

				<!~~ Phibrows Augenbrauen ~~>

				<div class="section-title text-start">
					<span>Microblading</span>
					<h2>Phibrows Augenbrauen</h2>
				</div>

				<div class="expertise-item">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading Härchenzeichnung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">325 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading inkl. Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">390 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Powderbrows Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">350 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

					</ul>
				</div>

				<!~~ Farbauffrischung Touch Up ~~>

				<div
				class="section-title text-start wow animate__animated animate__fadeInUp"
				data-wow-delay=".6s">
					<span>Microblading</span>
					<h2>Phibrows Augenbrauen</h2>
				</div>

				<div class="expertise-item hidden--mobile">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading Härchenzeichnung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">325 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading inkl. Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">390 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Powderbrows Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">350 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

					</ul>
				</div>

				<!~~ PhiRemoval | PMU & Tattooentfernung ~~>

				<div
				class="section-title text-start wow animate__animated animate__fadeInUp"
				data-wow-delay=".6s">
					<span>Microblading</span>
					<h2>Phibrows Augenbrauen</h2>
				</div>

				<div class="expertise-item hidden--mobile">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading Härchenzeichnung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">325 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading inkl. Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">390 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Powderbrows Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">350 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

					</ul>
				</div>

				<!~~ PhiLifting & Lamination ~~>

				<div
				class="section-title text-start wow animate__animated animate__fadeInUp"
				data-wow-delay="1.0s">
					<span>Microblading</span>
					<h2>Phibrows Augenbrauen</h2>
				</div>

				<div class="expertise-item hidden--mobile">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.1s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading Härchenzeichnung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">325 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.2s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Microblading inkl. Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">390 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.3s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Powderbrows Puderschattierung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">350 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<!~~ <p>Squads of Lyzo are incredibly professional and understand the situation of their clients and encourage them until obtaining victory</p> ~~>
								</div>
							</div>
						</li>

					</ul>
				</div>

			</div>

			<div class="col-lg-6 expertise-image-container">
				<div class="expertise-image">
					<img src="assets/img/home-one/10.jpg" alt="Expertise">
				</div>
			</div>
		</div>
	</div>

	<div class="container hidden--desktop">
		<div class="row align-items-center">
			<div class="col-sm-12 col-lg-6">

				<div
				class="section-title text-start wow animate__animated animate__fadeInUp"
				data-wow-delay=".7s">
					<span>Permanent Make-up</span>
					<h2>Farbauffrischung Touch Up</h2>
				</div>

				<div class="expertise-item">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Nachbehandlung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">75 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>Innerhalb 4 - 6 Wochen</p>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Auffrischung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">150 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>Innerhalb 12 - 18 Monate</p>
								</div>
							</div>
						</li>

					</ul>
				</div>
			</div>

			<div class="col-lg-6 expertise-image-container">
				<div class="expertise-image">
					<img src="assets/img/home-one/10.png" alt="Expertise">
				</div>
			</div>
		</div>
	</div>

	<div class="container hidden--desktop">
		<div class="row align-items-center">
			<div class="col-sm-12 col-lg-6">

				<div
				class="section-title text-start wow animate__animated animate__fadeInUp"
				data-wow-delay="1.0s">
					<span>Permanent Make-up</span>
					<h2>PhiRemoval | PMU & Tattooentfernung</h2>
				</div>

				<div class="expertise-item">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.1s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Entfernung von:</h3>
								</div>
								<div id="gridItem02">
									<span class="price">150 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>Augenbrauen, Lidstrich & Lippenkontur</p>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.2s">
							<div class="grid">
								<div id="gridItem01">
									<h3>ab der 2. Behandlung</h3>
								</div>
								<div id="gridItem02">
									<span class="price">100 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>+ jede weitere</p>
								</div>
							</div>
						</li>

					</ul>
				</div>
			</div>

			<div class="col-lg-6 expertise-image-container">
				<div class="expertise-image">
					<img src="assets/img/home-one/10.png" alt="Expertise">
				</div>
			</div>
		</div>
	</div>

	<div class="container hidden--desktop">
		<div class="row align-items-center">
			<div class="col-sm-12 col-lg-6">

				<div
				class="section-title text-start wow animate__animated animate__fadeInUp"
				data-wow-delay="1.3s">
					<span>Permanent Make-up</span>
					<h2>PhiLifting & Lamination</h2>
				</div>

				<div class="expertise-item">
					<ul>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.4s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Lash Lifting</h3>
								</div>
								<div id="gridItem02">
									<span class="price">59 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>inkl. Färbung & Keratin Botox</p>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.5s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Brow Lifting</h3>
								</div>
								<div id="gridItem02">
									<span class="price">59 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>inkl. Färbung & Keratin Botox</p>
								</div>
							</div>
						</li>

						<li class="wow animate__animated animate__fadeInUp" data-wow-delay="1.6s">
							<div class="grid">
								<div id="gridItem01">
									<h3>Lash- und Browlifting</h3>
								</div>
								<div id="gridItem02">
									<span class="price">99 €</span>
								</div>
								<div id="gridItem03">
									<div class="expertise-icon">
										<!~~ <i class="flaticon-experience"></i>
										<img src="assets/img/home-one/11.png" alt="Shape"> ~~>
									</div>
								</div>

								<div id="gridItem04">
									<p>inkl. Färbung & Keratin Botox</p>
								</div>
							</div>
						</li>


					</ul>
				</div>
			</div>

			<div class="col-lg-6 expertise-image-container">
				<div class="expertise-image">
					<img src="assets/img/home-one/10.png" alt="Expertise">
				</div>
			</div>
		</div>
	</div>
</section> -->


<!-- <section class="portfolio-area ptb-100">
	<div class="container">
		<div class="section-title">
			<span>PORTFOLIO</span>
			<h2>Check Out Our Popular Case Studies.</h2>
		</div>

		<div class="row">
			<div class="col-sm-6 col-lg-4">
				<div class="portfolio-item">
					<img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

					<div class="portfolio-inner">
						<span>Crime</span>
						<h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
						<p>15 Feb 2021</p>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-lg-4">
				<div class="portfolio-item">
					<img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

					<div class="portfolio-inner">
						<span>Rights</span>
						<h3><a routerLink="/case-study-details">Children Rights</a></h3>
						<p>16 Feb 2021</p>
					</div>
				</div>
			</div>

			<div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
				<div class="portfolio-item">
					<img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

					<div class="portfolio-inner">
						<span>Finance</span>
						<h3><a routerLink="/case-study-details">Money Loundering</a></h3>
						<p>17 Feb 2021</p>
					</div>
				</div>
			</div>
		</div>

		<div class="text-center">
			<a class="cmn-btn" routerLink="/case-study">See More</a>
		</div>
	</div>
</section> -->

<!-- <section class="team-area">
	<div class="container">
		<div class="section-title">
			<span>TEAM MEMBER</span>
			<h2>Meet Our Expert Attorneys</h2>
		</div>

		<div class="row">
			<div class="col-sm-6 col-lg-3">
				<div class="team-item">
					<img src="assets/img/home-one/team/1.jpg" alt="Team">

					<div class="team-inner">
						<ul>
							<li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
							<li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
							<li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
							<li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
						</ul>
						<h3><a routerLink="/attorney-details">Attor. Jerry Hudson</a></h3>
						<span>Family Consultant</span>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-lg-3">
				<div class="team-item">
					<img src="assets/img/home-one/team/2.jpg" alt="Team">

					<div class="team-inner">
						<ul>
							<li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
							<li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
							<li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
							<li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
						</ul>
						<h3><a routerLink="/attorney-details">Attor. Juho Hudson</a></h3>
						<span>Criminal Consultant</span>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-lg-3">
				<div class="team-item">
					<img src="assets/img/home-one/team/3.jpg" alt="Team">

					<div class="team-inner">
						<ul>
							<li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
							<li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
							<li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
							<li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
						</ul>
						<h3><a routerLink="/attorney-details">Attor. Sarah Se</a></h3>
						<span>Divorce Consultant</span>
					</div>
				</div>
			</div>

			<div class="col-sm-6 col-lg-3">
				<div class="team-item">
					<img src="assets/img/home-one/team/4.jpg" alt="Team">

					<div class="team-inner">
						<ul>
							<li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
							<li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
							<li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
							<li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
						</ul>
						<h3><a routerLink="/attorney-details">Attor. Aikin Ward</a></h3>
						<span>Business Consultant</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section id="section03">
	<div class="contact-form">
		<div class="loaction-area">
			<div class="container">
				<div class="row location-bg">
					<div class="col-sm-6 col-lg-4">
						<div class="location-item">
							<div class="location-icon">
								<i class="flaticon-pin"></i>
							</div>
							<h3>Adresse</h3>
							<ul>
								<li>Tiergartenstraße 35, 47533 Kleve</li>
							</ul>
							<!-- <a class="location-link" href="#" target="_blank">Wegbeschreibung</a> -->
						</div>
					</div>

					<div class="col-sm-6 col-lg-4">
						<div class="location-item">
							<div class="location-icon">
								<i class="flaticon-email"></i>
							</div>
							<h3>Email</h3>
							<ul>
								<li><a href="mailto:info@btbeauty.de">info@btbeauty.de</a></li>
							</ul>
							<!-- <a class="location-link" href="#" target="_blank">View on gogle map</a> -->
						</div>
					</div>

					<div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
						<div class="location-item">
							<div class="location-icon">
								<i class="flaticon-call"></i>
							</div>
							<h3>Telefon</h3>
							<ul>
								<li><a href="tel:+491747868394">+49 (0) 174 78 68 394</a></li>
							</ul>
							<!-- <a class="location-link" href="#" target="_blank">View on gogle map</a> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<form id="contactForm">
				<div class="row contact-wrap">

					<div class="col-sm-6 col-lg-6">
						<div class="form-group">
							<mat-form-field appearance="fill">
								<mat-label>Name</mat-label>
								<input matInput [formControl]="nameControl">
							</mat-form-field>
						</div>
					</div>

					<div class="col-sm-6 col-lg-6">
						<div class="form-group">
							<mat-form-field appearance="fill">
								<mat-label>Email</mat-label>
								<input matInput [formControl]="emailControl">
							</mat-form-field>
						</div>
					</div>

					<div class="col-sm-6 col-lg-6">
						<div class="form-group">
							<mat-form-field appearance="fill">
								<mat-label>Telefon</mat-label>
								<input
								matInput
								[errorStateMatcher]="matcher"
								[formControl]="phoneControl"
								required>
								<mat-error *ngIf="phoneHasError">
									Geben Sie Ihre Telefonnummer ein.
								</mat-error>
							</mat-form-field>
						</div>
					</div>

					<!-- <div class="col-sm-6 col-lg-6">
						<div class="form-group">
							<input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Betreff">
						</div>
					</div>

					<div class="col-md-12 col-lg-12">
						<div class="form-group">
							<textarea name="message" class="form-control" id="message" cols="30" rows="8"
								placeholder="Case Description"></textarea>
						</div>
					</div> -->

					<div class="privacy-note-container">
						<p>
							Beim Absenden, übermitteln Sie die Daten an BT Beauty. Die Informationspflichten zum Datenschutz, insbesondere zur Rechtsgrundlage, finden Sie unter diesem <a [routerLink]="['privacy-policy']">Link</a>.
						</p>
					</div>

					<div class="action-container col-md-12 col-lg-12">
						<button
						mat-flat-button
						color="accent"
						[loading]="loading"
    				[disabled]="loading"
						(click)="submitContactForm()">
							Rückruf anfordern
						</button>
						<!-- <div class="text-center">
							<button type="submit" class="contact-btn">Rückruf anfordern</button>
						</div> -->
					</div>
				</div>
			</form>
		</div>
	</div>
</section>

<!-- <section class="blog-area pt-100">
	<div class="container">
		<div class="section-title">
			<span>BLOG</span>
			<h2>Our Latest Blogs</h2>
		</div>

		<div class="blog-slider owl-theme owl-carousel">
			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/1.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Rights Case</span>
					<h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/2.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Business Case</span>
					<h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/3.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Criminal Case</span>
					<h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/1.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Rights Case</span>
					<h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/2.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Business Case</span>
					<h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/3.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Criminal Case</span>
					<h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/1.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Rights Case</span>
					<h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/2.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Business Case</span>
					<h3>
						<a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a>
					</h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>

			<div class="blog-item">
				<a routerLink="/blog-details">
					<img src="assets/img/home-one/blog/3.jpg" alt="Blog">
				</a>

				<div class="blog-inner">
					<span>Criminal Case</span>
					<h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
					<ul>
						<li><i class="icofont-calendar"></i> 20 Feb 2020</li>
						<li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
					</ul>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
					<a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
				</div>
			</div>
		</div>
	</div>
</section> -->