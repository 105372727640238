export const environment = {
  firebase: {
    projectId: 'btbeautyde-3b323',
    appId: '1:897409313344:web:ffdbf43b31f393d00f4da5',
    storageBucket: 'btbeautyde-3b323.appspot.com',
    apiKey: 'AIzaSyA-wIJq6Mf3XSgZdGWfiqCDKC1GX90GTAo',
    authDomain: 'btbeautyde-3b323.firebaseapp.com',
    messagingSenderId: '897409313344',
    measurementId: 'G-W5M7YSWMB9',
  },
  production: true
};
