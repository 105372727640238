import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailService } from 'src/app/services/email.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ContactForm } from './models/contact-form';
import LocomotiveScroll from 'locomotive-scroll';
import * as Rellax from 'rellax';

export class LeadFormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss'],
})
export class HomeOneComponent implements OnInit, AfterViewInit, OnDestroy {
  public loading = false;

  public matcher = new LeadFormErrorStateMatcher();
  public scroll: any;
  public phoneHasError = false;

  public nameControl = new FormControl('');
  public emailControl = new FormControl('');
  public phoneControl = new FormControl('');

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService,
    private layoutService: LayoutService,
  ) {}

  public ngOnInit(): void {
    // this.matSnackBar.open('Ihre Anfrage wurde versendet.', 'Schließen', {
    //   duration: 1000000,
    //   horizontalPosition: 'start',
    //   verticalPosition: 'bottom',
    // });

    this.layoutService.loading$.subscribe((next) => {
      this.loading = next;
    });
    this.phoneControl.valueChanges.subscribe((next) => {
      // console.log(next);
      if (this.phoneControl.hasError('required')) {
        this.phoneHasError = true;
        // this.changeDetectorRef.markForCheck();
      } else {
        this.phoneHasError = false;
        // this.changeDetectorRef.markForCheck();
      }
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      console.log(this.document.querySelector('[data-scroll-container]'));

      // if (this.document.querySelector('[data-scroll-container]')) {
      //   this.scroll = new LocomotiveScroll({
      //     el: document.querySelector('[data-scroll-container]'),
      //     inertia: 0.8,
      //     smooth: true,
      //     getDirection: true,
      //     smartphone: {
      //       smooth: true,
      //       inertia: 0.8,
      //       getDirection: true,
      //     },
      //     mobile: {
      //       smooth: true,
      //       inertia: 0.8,
      //       getDirection: true,
      //     },
      //     tablet: {
      //       smooth: true,
      //       inertia: 0.8,
      //       getDirection: true,
      //     },
      //   });
      // }

      const rellax1 = new Rellax('.custom-scroll-item--e1 svg', {
        speed: -2,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
      });

      const rellax3 = new Rellax('.custom-scroll-item--e2 svg', {
        speed: -4,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
      });

      const rellax2 = new Rellax('.custom-image__picture img', {
        speed: 2,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
      });
    });
  }

  public ngOnDestroy(): void {
    this.scroll.destroy();
  }

  public clear(): void {
    this.nameControl.reset();
    this.nameControl.setErrors(null);
    this.emailControl.reset();
    this.emailControl.setErrors(null);
    this.phoneControl.reset();
    this.phoneControl.setErrors(null);

    this.phoneHasError = false;
  }

  public submitContactForm(): void {
    if (!this.phoneControl.hasError('required')) {
      this.layoutService.loading$.next(true);
      const data: ContactForm = {
        name: this.nameControl.value ?? '',
        email: this.emailControl.value ?? '',
        phone: this.phoneControl.value ?? '',
      };
      this.emailService.homeOneContactFormSubmit(data).subscribe(
        (next) => {
          if (next) {
            this.layoutService.loading$.next(false);
            this.phoneHasError = false;
            this.matSnackBar.open(
              'Ihre Anfrage wurde versendet.',
              'Schließen',
              {
                duration: 10000,
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
              },
            );
            this.clear();
          }
        },
        (error: any) => {
          this.layoutService.loading$.next(false);
          console.log(error);
          this.matSnackBar.open(
            'Ihre Anfrage konnte nicht versendet werden.',
            'Schließen',
            {
              duration: 10000,
              horizontalPosition: 'start',
              verticalPosition: 'bottom',
            },
          );
        },
        () => {
          // console.log('completed');
        },
      );
    } else {
      this.phoneHasError = true;
    }
  }
}
